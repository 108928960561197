import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-shaka-player',
  templateUrl: './shaka-player.component.html',
  styleUrls: ['./shaka-player.component.scss']
})
export class ShakaPlayerComponent implements OnInit {

  constructor(private modalRef: BsModalRef) { 

    

  }

  ngOnInit(): void {
  }

  
  public onCancel(): void {
   
    this.modalRef.hide();
   
  }
}
