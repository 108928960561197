<div class="modal-content">

  <div class="modal-body">
    <div class="card p-4 register">
      <div class="card-body p-0" id="register">
        <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
        <div *ngIf="!isChangePasswordSucc">
          <h1 class="loginLabel">Change Password</h1>
          <br>
          <form [formGroup]="changePasswordFormGroup" autocomplete="off">

            <div class="form-group">
              <label class="register-label mb-1" for="inputPassword">Current password <span
                  class="required-field">*</span></label>
              <input type="password" class="form-control" id="inputPassword" placeholder="Current password"
                autocomplete="new-password" formControlName="oldPassword"
                [ngClass]="{ 'is-invalid': isCheckValidation && changePasswordControls.oldPassword.errors }">
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.oldPassword.errors) ||
                            (!!changePasswordControls?.oldPassword.touched && !!changePasswordControls?.oldPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.oldPassword.errors.required">
                  {{ changePasswordMessage.oldPassword.errors.required }}
                </div>
              </small>
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.oldPassword.errors) ||
                            (!!changePasswordControls?.oldPassword.touched && !!changePasswordControls?.oldPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.oldPassword.errors.passwordInvalid">
                  {{ changePasswordMessage.oldPassword.errors.passwordInvalid }}
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="register-label mb-1" for="inputPassword">New password <span
                  class="required-field">*</span></label>
              <input type="password" class="form-control" id="inputPassword" placeholder="New password"
                autocomplete="new-password" formControlName="newPassword"
                [ngClass]="{ 'is-invalid': isCheckValidation && changePasswordControls.newPassword.errors }">
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.newPassword.errors) ||
                            (!!changePasswordControls?.newPassword.touched && !!changePasswordControls?.newPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.newPassword.errors.required">
                  {{ changePasswordMessage.newPassword.errors.required }}
                </div>
              </small>
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.newPassword.errors) ||
                            (!!changePasswordControls?.newPassword.touched && !!changePasswordControls?.newPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.newPassword.errors.passwordInvalid">
                  {{ changePasswordMessage.newPassword.errors.passwordInvalid }}
                </div>
              </small>
            </div>

            <div class="form-group">
              <label class="register-label mb-1" for="inputPassword">Confirm new password <span
                  class="required-field">*</span></label>
              <input type="password" class="form-control" id="inputPassword" placeholder="Confirm new password"
                autocomplete="new-password" formControlName="confirmNewPassword"
                [ngClass]="{ 'is-invalid': isCheckValidation && changePasswordControls.confirmNewPassword.errors }">
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.confirmNewPassword.errors) ||
                            (!!changePasswordControls?.confirmNewPassword.touched && !!changePasswordControls?.confirmNewPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.confirmNewPassword.errors.required">
                  {{ changePasswordMessage.confirmNewPassword.errors.required }}
                </div>
              </small>
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.confirmNewPassword.errors) ||
                            (!!changePasswordControls?.confirmNewPassword.touched && !!changePasswordControls?.confirmNewPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.confirmNewPassword.errors.mustMatch">
                  {{ changePasswordMessage.confirmNewPassword.errors.mustMatch }}
                </div>
              </small>
              <small class="form-error-text"
                *ngIf="(isCheckValidation && !!changePasswordControls?.confirmNewPassword.errors) ||
                            (!!changePasswordControls?.confirmNewPassword.touched && !!changePasswordControls?.confirmNewPassword.invalid)">
                <div class="pt-2" *ngIf="!!changePasswordControls?.confirmNewPassword.errors.passwordInvalid">
                  {{ changePasswordMessage.confirmNewPassword.errors.passwordInvalid }}
                </div>
              </small>
            </div>

            <div id="login-box">
              <div class="d-flex justify-content-center">
                <button id="register-btn" class="btn px-2" [disabled]="changePasswordFormGroup.invalid"
                  (click)="onChangePasswordClick()">Change Password</button>

                <!--button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button-->
              </div>
            </div>

          </form>
        </div>
        <div class="text-center pt-3">
          <p id="error-message">
            {{ errorMessage }}
          </p>
        </div>
      </div>

    </div>
  </div>
</div>