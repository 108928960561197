<div class="modal-content">

    <div class="modal-header">
        
        <button type="button" style="font-size: 40px !important;" class="close" aria-label="Close" (click)="onCancel()">
            x
        </button>
    </div>

    <div class="modal-body" style="text-align: center; padding: 30px; padding-top: 50px; padding-bottom: 50px;">
        
      <a (click)="watchPromo('https://vimeo.com/967910193/b468bc1030')" style="" target="_blank"> Mirror your Apple Device on your TV</a>
        <br><br>
      <a (click)="watchPromo('https://vimeo.com/783677529/95458c8f20')"  style=" " target="_blank"> Mirror your Android Device on your TV</a>
    </div>
</div>