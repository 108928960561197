<div class="siteFooter" style="border-top: none !important;" *ngIf="footerTemp == 0" >
  <!-- copyright information -->
  <div class="col-md-2 col-sm-12" >
    <div class="row">
            <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                

                      <a (click)="onVideoLinkClick('https://vimeo.com/776748324/97b02f080c', 'V')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                          What is a SWIGIT?</a>
                        
                    
            <br>

                info@swigmedia.com
            </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12" >
    <div class="row">
    <div class="col-lg-12 col-sm-12 footer_big"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center;  ">
                SWIGIT... Bringing you 'One-Click-To-Watch' entertainment you like, <Br>
                  wherever  you are.
           
            </div>
          </div>
  </div> 
  <div class="col-md-4 col-sm-12" style="border: 1px solid red; background-color: red;">
    <div class="row">
    <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                <a *ngIf="!isUserLogin" (click)="openRegisterModal()" style="cursor: pointer">
                Register free for Notifications, Free-To-Watch, <br>
                Discounts and Rewards
                </a>
                <a *ngIf="isUserLogin" (click)="openRegisterMessageModal()" style="cursor: pointer">
                  Register free for Notifications, Free-To-Watch, <br>
                  Discounts and Rewards
                </a>
            </div>
          </div>
  </div>
  
</div>
<div class="siteFooter" style="border-top: none !important;" *ngIf="footerTemp == 1" >
  <!-- copyright information -->
  <div class="col-md-2 col-sm-12" >
    <div class="row">
            <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                <!--SWIGIT™ Patent Pending
            <br>
                <a href="https://swigit.com/"
                    target="_blank">swigit.com</a>-->
                    <!--
                    <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                    -->
                                      
                      <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                        <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

                      <a (click)="onVideoLinkClick('https://vimeo.com/776748324/97b02f080c', 'V')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                          What is a SWIGIT?</a>
                        
                    
            <br>

                info@swigmedia.com
            </div>
    </div>
  </div>
  <div class="col-md-8 col-sm-12" >
    <!--div class="row">
      <div class="col-lg-12 col-sm-12 footer_big"
        style="padding-bottom: 10px;margin: auto; color: white; text-align: center; 
                font-family: 'Times New Roman', Times, serif; font-size: 3.5rem !important;  ">
               <a href="{{appBundlesHPFoot.bundle_logo_url}}#" target="_blank">
                <p ><img src="https://swigappmanager.com/uploads/stream_images/everywhere-theatre-white-transparent-logo.png" style="height: 120px !important;">
                </p> 
                <p style=" font-size: 1.3rem !important; font-family: 'Times New Roman', Times, serif;  ">
                    Watch movies and shows EVERYWHERE you go!</p>
                  </a>
      </div>
    </div-->
  </div> 
  <div class="col-md-2 col-sm-12" style="">
    <div class="row">
    <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                <a *ngIf="!isUserLogin" (click)="openRegisterModal()" style="cursor: pointer" class="btn d-block hvr-sweep-to-right" >
                Free Registeration 
                </a>
                 
                <a *ngIf="isUserLogin" (click)="openRegisterMessageModal()" style="cursor: pointer"  class="btn d-block hvr-sweep-to-right" >
                  Free Registeration 
                </a>
                
                
                <br>
                  For Discounts and Rewards
            </div>
          </div>
  </div>
  
</div>


