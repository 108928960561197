<div class="modal-content">

    <div class="modal-body">
        <div class="card p-4 register">
            <div class="card-body pt-0" id="register">
                <div>
                    <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
                    <h1 class="" *ngIf="!isSignUpSucc && fromVariable != 'N' && fromVariable != 'D'" style="font-size: 27px; text-align: center; font-weight: bold;">Register</h1>
                   

                    <h1 class="" *ngIf="!isSignUpSucc && fromVariable == 'D'" style="font-size: 23px; text-align: center; font-weight: bold;">Ticket Purchase</h1>
                    <h1 class="" *ngIf="!isSignUpSucc && fromVariable == 'D'" style="font-size: 16px; font-weight: normal;">
                   Creating an account enables seamless access to the purchased pay-per-view across all connected devices 
                   and allows rewatching OnDemand content for up to 24 hours from the time of purchase.
                    </h1>
                    
                    <h1 class="" *ngIf="!isSignUpSucc && fromVariable == 'N'" style="font-size: 16px; font-weight: normal;">
                        Thank you for Registering for 
                        Notifications, Discounts and Free-To-Watch Shows and Live Events. 
                    </h1>

                    <br>
                    <form [formGroup]="registerFormGroup" autocomplete="off" *ngIf="!isSignUpSucc">
                        <!-- <div *ngIf="!forgetPasswordMode"> -->
                        <div class="form-group">
                            <label class="register-label mb-1" for="inputUserName">Name <span
                                    class="required-field">*</span></label>
                            <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                                id="inputUserName" placeholder="Name" formControlName="name"
                                [ngClass]="{ 'is-invalid': isCheckValidation && registerControls.name.errors }">
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.name.errors) ||
                                (!!registerControls?.name.touched && !!registerControls?.name.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.name.errors.required">
                                    {{ registerMessage.name.errors.required }}
                                </ng-container>
                            </small>
                        </div>

                        <!--div class="form-group">
                            <label class="register-label mb-1" for="inputUserName">User Name <span
                                    class="required-field">*</span></label>
                            <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                                id="inputUserName" placeholder="User Name" formControlName="username"
                                [ngClass]="{ 'is-invalid': isCheckValidation && registerControls.username.errors }">
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.username.errors) ||
                                (!!registerControls?.username.touched && !!registerControls?.username.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.username.errors.required">
                                    {{ registerMessage.username.errors.required }}
                                </ng-container>
                            </small>
                        </div-->

                        <div class="form-group">
                            <label class="register-label mb-1" for="inputUserName">Email Address <span
                                    class="required-field">*</span></label>
                            <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                                id="inputUserName" placeholder="Email Address" formControlName="email"
                                [ngClass]="{ 'is-invalid': isCheckValidation && registerControls.email.errors }">
                                <label class="form-white-text" >
                                    (Please enter email address in lowercase letters)
                                 </label><Br>
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.email.errors) ||
                            (!!registerControls?.email.touched && !!registerControls?.email.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.email.errors.required">
                                    {{ registerMessage.email.errors.required }}
                                </ng-container>
                            </small>
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.email.errors) ||
                            (!!registerControls?.email.touched && !!registerControls?.email.invalid)">
                                <ng-container class="pt-2" *ngIf="registerControls?.email.errors.checkEmail">
                                    {{ registerMessage.email.errors.invalid }}
                                </ng-container>
                            </small>
                        </div>
                        <div class="form-group">
                            <label class="register-label mb-1" for="inputPassword">Password <span
                                    class="required-field">*</span></label>
                            <input nbInput fullWidth fieldSize="giant" type="password" class="form-control"
                                id="inputPassword" placeholder="Password" autocomplete="new-password"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid': isCheckValidation && registerControls.password.errors }">
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.password.errors) ||
                            (!!registerControls?.password.touched && !!registerControls?.password.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.password.errors.required">
                                    {{ registerMessage.password.errors.required }}
                                </ng-container>
                            </small>
                            <small class="form-error-text" *ngIf="(isCheckValidation && !!registerControls?.password.errors) ||
                            (!!registerControls?.password.touched && !!registerControls?.password.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.password.errors.passwordInvalid">
                                    {{ registerMessage.password.errors.passwordInvalid }}
                                </ng-container>
                            </small>
                        </div>

                        <div class="form-group">
                            <label class="register-label mb-1" for="inputPassword">Confirm Password <span
                                    class="required-field">*</span></label>
                            <input nbInput fullWidth fieldSize="giant" type="password" class="form-control"
                                id="inputPassword" placeholder="Confirm Password" autocomplete="new-password"
                                formControlName="confirmPassword"
                                [ngClass]="{ 'is-invalid': isCheckValidation && registerControls.confirmPassword.errors }">
                            <small class="form-error-text"
                                *ngIf="(isCheckValidation && !!registerControls?.confirmPassword.errors) ||
                            (!!registerControls?.confirmPassword.touched && !!registerControls?.confirmPassword.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.confirmPassword.errors.mustMatch">
                                    {{ registerMessage.confirmPassword.errors.mustMatch }}
                                </ng-container>
                            </small>
                            <small class="form-error-text"
                                *ngIf="(isCheckValidation && !!registerControls?.confirmPassword.errors) ||
                            (!!registerControls?.confirmPassword.touched && !!registerControls?.confirmPassword.invalid)">
                                <ng-container class="pt-2" *ngIf="!!registerControls?.confirmPassword.errors.passwordInvalid">
                                    {{ registerMessage.confirmPassword.errors.passwordInvalid }}
                                </ng-container>
                            </small>
                        </div>
                        <div class="form-group" style="color: white;">
                            <input nbInput   type="checkbox" class="" style="height: 20px !important;"  
                                id="inputNewsletter" formControlName="inputNewsletter"> &nbsp; 
                            Register free for 
                                Notifications, Free-To-Watch, Discounts and Rewards  
                            
                               
                        </div>
                        <div id="login-box">
                            <!-- <nb-checkbox name="rememberMe" class="float-left">Remember me</nb-checkbox> -->
                            <!-- <nb-checkbox name="rememberMe" class="float-right">Remember me</nb-checkbox> -->
                            <div class="d-flex justify-content-center">
                                <button id="register-btn" class="btn px-4" *ngIf="!showLoading"
                                    [disabled]="registerFormGroup.invalid || showLoading "
                                    (click)="onRegisterClick()">Register</button>
                                <img src="../../assets/loader.gif" *ngIf="showLoading"  style="padding-right:40px !important; height: 40px !important;">
                                <!--button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button-->
                            </div>
                        </div>

                    </form>
                </div>
                <div class="text-center">
                    <p id="error-message">
                        {{ errorMessage }}
                    </p>
                </div>
                <div *ngIf="!isSignUpSucc">
                    <div class="text-center">
                        <p style="color: #a8a7a7;">
                            Already have an account?
                        </p>
                    </div>
                    <div class="text-center">
                        <p class="createAccount" (click)="onLogin()">
                            Login
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-spinner *ngIf="showLoading">
    </app-spinner>