<div class="modal-content">

  <div class="modal-body">
    <div class="card p-4 support">
      <div class="card-body" id="support" >
        <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
      <div  *ngIf="errorMessage === ''">
        
        <h2 class="loginLabel" style="text-align: center;">Support Form</h2>
        <br>
        <div class="fontColorWhite mb-5">
          If you are experiencing any difficulties with the Registration Process, Login, Transaction or have not
          received
          an
          anticipated Access Code to an event after you have made a Donation or Payment, please complete the following
          form.
          Someone from or team will reply as soon as possible. Apologies for the inconvenience and we will strive
          assist.
        </div>

        <form [formGroup]="supportFormGroup" autocomplete="off">
          <!-- <div *ngIf="!forgetPasswordMode"> -->
          <div class="form-group">
            <div class="row col-md-12">
              <label class="support-label col-md-3 col-sm-12 pl-0" for="inputUserName">Name <span class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control col-md-9 col-sm-12"
                id="inputUserName" placeholder="Name" formControlName="name"
                [ngClass]="{ 'is-invalid': isCheckValidation && supportControls.name.errors }">
            </div>
            <small class="form-error-text col-md-12 offset-md-3 offset-sm-0" *ngIf="(isCheckValidation && !!supportControls?.name.errors) ||
                    (!!supportControls?.name.touched && !!supportControls?.name.invalid)">
              <ng-container class="pt-2" *ngIf="!!supportControls?.name.errors.required">
                {{ supportMessage.name.errors.required }}
              </ng-container>
            </small>
          </div>

          <div class="form-group">
            <div class="row col-md-12">
              <label class="support-label col-md-3 col-sm-12 pl-0" for="inputUserName">Email <span class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control col-md-9 col-sm-12"
                id="inputEmail" placeholder="Email" formControlName="email"
                [ngClass]="{ 'is-invalid': isCheckValidation && supportControls.name.errors }">
            </div>
            <small class="form-error-text col-md-12 offset-md-3 offset-sm-0" *ngIf="(isCheckValidation && !!supportControls?.email.errors) ||
                    (!!supportControls?.email.touched && !!supportControls?.email.invalid)">
              <ng-container class="pt-2" *ngIf="!!supportControls?.email.errors.required">
                {{ supportMessage.email.errors.required }}
              </ng-container>
              <ng-container class="pt-2" *ngIf="supportControls?.email.errors.checkEmail">
                {{ supportMessage.email.errors.invalid }}
              </ng-container>
            </small>
          
          </div>

          <div class="form-group">
            <div class="row col-md-12">
              <label class="support-label col-md-3 col-sm-12 p-0" for="inputUserName">Message <span
                  class="required-field">*</span></label>
              <textarea nbInput fullWidth formAutoFocus type="text" class="form-control col-md-9 col-sm-12" id="inputEmail"
                placeholder="Message" formControlName="message"
                [ngClass]="{ 'is-invalid': isCheckValidation && supportControls.name.errors }"></textarea>
              <!-- <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control col-10"
                id="inputEmail" placeholder="Message" formControlName="message"
                [ngClass]="{ 'is-invalid': isCheckValidation && supportControls.name.errors }"> -->
            </div>
            <small class="form-info-text">
              <div class="offset-md-3 offset-sm-0" style="padding: .375rem .75rem;">
                Note: Please be as specific as possible. If you are getting the error message, please copy and send that
                as well
              </div>
            </small>
            <small class="form-error-text col-md-12 offset-md-3 offset-sm-0" *ngIf="(isCheckValidation && !!supportControls?.message.errors) ||
                    (!!supportControls?.message.touched && !!supportControls?.message.invalid)">
              <ng-container class="pt-2" *ngIf="!!supportControls?.message.errors.required">
                {{ supportMessage.message.errors.required }}
              </ng-container>
            </small>
          </div>

          <div id="login-box">
            <div class="d-flex justify-content-center">
              <button id="support-btn" class="btn px-4" [disabled]="supportFormGroup.invalid || showLoading " *ngIf="!showLoading"
                (click)="onSubmitClick()">Submit</button>
                <img src="../../assets/loader.gif" *ngIf="showLoading"  style="padding-right:40px !important; height: 40px !important;">
              <!--button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button-->
            </div>
          </div>

        </form>
      </div>
      </div>
      <div class="text-center">
        <p id="error-message">
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</div>

<app-spinner *ngIf="showLoading">
</app-spinner>