<div class="modal-content">

    <div class="modal-body">
        <div class="card p-4 login">
            <div class="card-body p-0" id="login">
                <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
                <h2 class="loginLabel" style="text-align:center">Forgot password</h2>
                <br>
                <form [formGroup]="forgotPasswordFormGroup" autocomplete="off">
                    <div class="form-group" *ngIf="!isProcessCompleted">

                        <label class="form-label mb-1 loginLabel" for="inputUserName">Email address <span
                                class="required-field">*</span></label>

                        <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                            id="inputUserName" placeholder="Email address" formControlName="email"
                            [ngClass]="{ 'is-invalid': isCheckValidation && forgotPasswordControls.email.errors }">
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!forgotPasswordControls?.email.errors) ||
                            (!!forgotPasswordControls?.email.touched && !!forgotPasswordControls?.email.invalid)">
                            <div class="pt-2" *ngIf="!!forgotPasswordControls?.email.errors.required">
                                {{ forgotPasswordMessage.email.errors.required }}
                            </div>
                        </small>
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!forgotPasswordControls?.email.errors) ||
                            (!!forgotPasswordControls?.email.touched && !!forgotPasswordControls?.email.invalid)">
                            <div class="pt-2" *ngIf="forgotPasswordControls?.email.errors.checkEmail">
                                {{ forgotPasswordMessage.email.errors.invalid }}
                            </div>
                        </small>

                    </div>
                    <div id="login-box" style="padding-top: 10px;" *ngIf="!isProcessCompleted">
                        <div class="d-flex justify-content-center">
                            <button id="login-btn" class="btn px-4" *ngIf="!showLoading" (click)="onForgotPasswordClick()">Submit</button>
                            <img src="../../assets/loader.gif" *ngIf="showLoading"  style="padding-right:40px !important; height: 40px !important;">
                            <!--button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button-->
                        </div>
                    </div>
                    <div class="text-center">
                        <p id="error-message">
                            {{ errorMessage }}
                        </p>
                    </div>
                    <div class="text-center" *ngIf="!isProcessCompleted">
                        <p style="color: #a8a7a7;">
                            Already have an account?
                        </p>
                    </div>
                    <div class="text-center" *ngIf="!isProcessCompleted">
                        <p class="createAccount" (click)="onLoginClick()">
                            Login
                        </p>
                    </div>
                    <div class="text-center" *ngIf="!isProcessCompleted">
                        <p style="color: #a8a7a7;">
                            Don't have an account?
                        </p>
                    </div>
                    <div class="text-center" *ngIf="!isProcessCompleted">
                        <p class="createAccount" (click)="onCreateAccount()">
                            Create an account
                        </p>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>