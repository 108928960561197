<div class="modal-content">

    <div class="modal-body">
        <div class="card p-4 login">
            <div class="card-body" id="login">
                <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
                <h2 class="loginLabel" style="text-align: center;">Login</h2>
                <!--h1 class="" style="font-size: 16px; font-weight: normal;">
 
                    For security reasons, viewing of this event is limited to registered account holders. 
                    If you have an account, please log in or create a free account. Once you have signed in, 
                    you will be asked to enter your access code. <br> Thank you.
                    </h1-->
                <br>
                <form [formGroup]="loginFormGroup" autocomplete="off">
                    <!-- <div *ngIf="!forgetPasswordMode"> -->
                    <div class="form-group">

                        <label class="form-label mb-1 loginLabel" for="inputUserName">Email Address <span
                                class="required-field">*</span></label>

                        <input nbInput fullWidth fieldSize="giant" formAutoFocus type="text" class="form-control"
                            id="inputUserName" placeholder="Email Address" formControlName="email"
                            [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.email.errors }">
                        <label class="form-white-text" >
                           (Please enter email address in lowercase letters)
                        </label><Br>
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.email.errors) ||
                            (!!loginControls?.email.touched && !!loginControls?.email.invalid)">
                            <ng-container class="pt-2" *ngIf="!!loginControls?.email.errors.required">
                                {{ loginMessage.email.errors.required }}
                            </ng-container>
                        </small>
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.email.errors) ||
                            (!!loginControls?.email.touched && !!loginControls?.email.invalid)">
                            <ng-container class="pt-2" *ngIf="loginControls?.email.errors.checkEmail">
                                {{ loginMessage.email.errors.invalid }}
                            </ng-container>
                        </small>

                    </div>
                    <div class="form-group">

                        <label class="form-label mb-1 loginLabel" for="inputPassword">Password <span
                                class="required-field">*</span></label>
                        <input nbInput fullWidth fieldSize="giant" type="password" class="form-control"
                            id="inputPassword" placeholder="Password" autocomplete="new-password"
                            formControlName="password"
                            [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.password.errors }">
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.password.errors) ||
                            (!!loginControls?.password.touched && !!loginControls?.password.invalid)">
                            <ng-container class="pt-2" *ngIf="!!loginControls?.password.errors.required">
                                {{ loginMessage.password.errors.required }}
                            </ng-container>
                        </small>
                    </div>
                    <!--div id="activation-code" *ngIf="!isSignUp">
                        <label class="form-label mb-1 loginLabel" for="inputActivationCode">Activation Code <span
                                class="required-field">*</span></label>
                        <input nbInput fullWidth fieldSize="giant" type="text" class="form-control"
                            id="inputActivationCode" placeholder="Activation Code" formControlName="activationcode"
                            [ngClass]="{ 'is-invalid': isCheckValidation && loginControls.activationcode.errors }">
                        <small class="form-error-text" *ngIf="(isCheckValidation && !!loginControls?.activationcode.errors) ||
                                (!!loginControls?.activationcode.touched && !!loginControls?.activationcode.invalid)">
                            <ng-container class="pt-2" *ngIf="!!loginControls?.activationcode.errors.required">

                            </ng-container>
                        </small>


                    </div-->

                    <div class="text-center">
                        <p id="error-message">
                            {{ errorMessage }}
                        </p>
                    </div>

                    <div id="login-box" style="padding-top: 10px;">
                        <!-- <nb-checkbox name="rememberMe" class="float-left">Remember me</nb-checkbox> -->
                        <!-- <nb-checkbox name="rememberMe" class="float-right">Remember me</nb-checkbox> -->
                        <div class="d-flex justify-content-center">
                            <button id="login-btn" class="btn px-4" *ngIf="!showLoading" 
                                (click)="onLoginClick()">Login</button>

                            <!--button id="active-btn" class="btn px-4" *ngIf="!isSignUp && !showLoading"
                                [disabled]="loginFormGroup.invalid || showLoading "
                                (click)="onActiveClick()">Activate</button-->
                                <img src="../../assets/loader.gif" *ngIf="showLoading"  style="padding-right:40px !important; height: 40px !important;">
                            <!--button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button-->
                        </div>
                    </div>

                    <div class="row">
                        <div class="" style="text-align: center; width: 100%;">
                            <div (click)="onForgotPassword()" style="font-size: 1.2rem;cursor:pointer;color: white; text-align: center;">
                                Forgot password?
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="text-center">
                        <p style="color: #a8a7a7;">
                            Don't have an account?
                        </p>
                    </div>
                    <div class="text-center">
                        <p class="createAccount" (click)="onCreateAccount()">
                            Create Account
                        </p>
                    </div>
                </form>

            </div>
        </div>
    </div>