import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, ModalOptions  } from 'ngx-bootstrap/modal';
import Player from '@vimeo/player';
import { AppStorage } from '../providers/enum';
import { StorageService } from '../shared/services/storage.service';

@Component({
  selector: 'app-vimeoplayer',
  templateUrl: './vimeoplayer.component.html',
  styleUrls: ['./vimeoplayer.component.scss']
})
export class VimeoplayerComponent implements OnInit {

  @ViewChild('vimeovideoTemplate') public vimeovideoTemplate: ModalDirective;

  public player;
  public streamDetails: any;
  public streamURL: any;
  
  constructor(private modalRef: BsModalRef,
    public options: ModalOptions,
    private storageService: StorageService) { }

  ngOnInit(): void {
   // let streamURL = this.storageService.get(AppStorage.streamPlayUrl, true);
  //  console.log("streamURL:::", streamURL);
    this.streamURL = this.options.initialState;
   // alert( screen.height);

    

    if(screen.height > screen.width)
    {

      var widthVar = (screen.width)+"px";
      //alert("width="+widthVar);
        const options1 = {
          url: this.streamURL.url, //https://vimeo.com/535582526",https://vimeo.com/545202263/145f6c9602
          width: widthVar,
          autoplay: true,
          muted: false,
          loop: true,
          responsive: false
        };
        
        var videoPlayer = new Player('myVimeoVideo', options1);
        videoPlayer.autoplay = true;
        //alert("vid="+videoPlayer.autoplay);
        videoPlayer.on('play', function() {
        // console.log('Played the video');
          //alert("dddd");
        });
    }
    else
    {
      
      var heightVar = (screen.height - 200)+"px";
      //alert("height="+heightVar);
        const options1 = {
          url: this.streamURL.url, //https://vimeo.com/535582526",https://vimeo.com/545202263/145f6c9602
          height: heightVar,
          autoplay: true,
          muted: false,
          loop: true,
          responsive: false
        };
        
        var videoPlayer = new Player('myVimeoVideo', options1);
        videoPlayer.autoplay = true;
        //alert("vid="+videoPlayer.autoplay);
        videoPlayer.on('play', function() {
        // console.log('Played the video');
          //alert("dddd");
        });
    }

       

  }

  public onCancel(): void {
   
    this.modalRef.hide();
   
  }

}
