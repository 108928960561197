import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService,  ModalOptions } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { AuthRequest, LoginService, AuthResponse } from '../login/login.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { RegisterAction, AccountActivationAction } from '../providers/constant';
import { RegisterService } from './register.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { StorageService } from '../shared/services/storage.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  //#region variables

  @ViewChild('loginTemplate') public loginTemplate: ModalDirective;

  public registerMessage: any;
  public accountActivationMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public isSignUpSucc: boolean = false;

  public registerFormGroup: FormGroup;
  public accountActivationFormGroup: FormGroup;
  public streamDetails;
  public fromT: any;
  private subs = new SubSink();
  public fromVariable: any;

  //#endregion

  //#region life-cycle hook

  constructor(private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private loginService: LoginService,
    private registerService: RegisterService,
    private storageService: StorageService,
    public options: ModalOptions,
    private router: Router) { }

  ngOnInit(): void {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getRegisterMessage();
    this.createRegisterFormGroup();
    this.createAccountActivationFormGroup();

    this.fromT = this.options.initialState;
    this.fromVariable = this.fromT.fromP;
   // alert(this.fromVariable);
    let appCode = this.storageService.get(AppStorage.appCodeLS, true);
    //console.log(appCode);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getRegisterMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.registerMessage = messageList[MessageFor.RegisterForm];
        this.accountActivationMessage = messageList[MessageFor.AccountActivationFrom];
      });
  }

  private createRegisterFormGroup(): void {
    this.registerFormGroup = this.formBuilder.group({
     
      name: [null, Validators.required],
      inputNewsletter: [null],
      email: [null, [Validators.required, CommonUtilsService.checkEmail]],
      password: [null, [Validators.required, CommonUtilsService.passwordValidator]],
      confirmPassword: [null, [Validators.required, CommonUtilsService.passwordValidator]],
    }, {
      validator: [
        CommonUtilsService.mustMatch('password', 'confirmPassword'),
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //username: [null, Validators.required],
  private createAccountActivationFormGroup(): void {
    this.accountActivationFormGroup = this.formBuilder.group({
      emailOrusername: [null, Validators.required],
      accountActivationCode: [null, Validators.required]
    }, {
      validator: [
        CommonUtilsService.checkEmail('emailOrusername', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get registerControls() { return this.registerFormGroup.controls; }

  public get accountActivationControls() { return this.accountActivationFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onRegisterClick(): void {
    if (this.registerFormGroup.invalid) {
      return;
    }

    var vl = this.registerFormGroup.controls.inputNewsletter.value+"";
    var nwsr = 'N';

    //alert(vl);
    if(vl === "true")
      nwsr = 'Y';
    else
      nwsr = 'N';

    this.showLoading = true;
    this.isCheckValidation = true;
    let appCode = this.storageService.get(AppStorage.appCodeLS, true);
    //console.log("str====",this.streamDetails[0].app_code);
    const formData = new FormData();
    formData.append("username", "user"); //this.registerControls.username.value
    formData.append("name", this.registerControls.name.value);
    formData.append("email", this.registerControls.email.value);
    formData.append("password", this.registerControls.password.value);
    formData.append("confirmPassword", this.registerControls.confirmPassword.value);
    formData.append("newsletter_flag", nwsr);
    //formData.append("appId", appCode);
    formData.append("appId", appCode);
    formData.append("postAction", RegisterAction);
    formData.append("isBypassEmailVerificationStep", "N");

    this.subs.sink = this.registerService.createUser(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);
            //this.isSignUp = true;
            // this.router.navigateByUrl('/');
            // this.onCancel();
            this.isSignUpSucc = true;
            this.errorMessage = authResponse.msg;
            // this.errorMessage = "Signup Successfully done. Please check your email for Access Code and come back to Login here.";
            window.location.reload();
          } else {
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );

      
  }

  public onLogin(): void {
    this.onCancel();
    this.modalRef = this.modalService.show(
      LoginComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }

  /*
  public onActivateClick(): void {
    if (this.accountActivationFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("emailOrusername", this.registerControls.emailOrusername.value);
    formData.append("accountActivationCode", this.registerControls.accountActivationCode.value);
    formData.append("appId", APP_ID);
    formData.append("postAction", AccountActivationAction);

    this.subs.sink = this.registerService.accountActivation(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            // this.isSignUp = true;
            window.location.reload();
            // this.router.navigateByUrl('/');
            // this.onCancel();
          } else {
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }*/

  //#endregion

}
